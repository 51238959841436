import React, { useEffect, useState } from "react";
import AlertDialog from "../components/AlertDialog";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  useMediaQuery
} from "@mui/material";
import { api } from "common";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CircularLoading from "components/CircularLoading";
import { useParams } from "react-router-dom";
import { colors } from "components/Theme/WebTheme";
import {
  MAIN_COLOR,
  SECONDORY_COLOR,
  FONT_FAMILY
} from "../common/sharedFunctions";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  typography: {
    fontFamily: FONT_FAMILY
  },
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR
      }
    },
    "& input": {
      fontFamily: FONT_FAMILY
    }
  },
  selectField: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR
    }
  },
  rootRtl_3: {
    "& label": {
      right: 17,
      left: "auto",
      paddingRight: 12,
      fontFamily: FONT_FAMILY
    },
    "& legend": {
      textAlign: "right",
      marginRight: 20,
      fontFamily: FONT_FAMILY
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR
      }
    },
    "& input": {
      fontFamily: FONT_FAMILY
    }
  },
  rootRtl: {
    "& label": {
      right: 20,
      left: "auto",
      paddingRight: 20,
      fontFamily: FONT_FAMILY
    },
    "& legend": {
      textAlign: "right",
      marginRight: 15,
      fontFamily: FONT_FAMILY
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR
      }
    },
    "& input": {
      fontFamily: FONT_FAMILY
    }
  },
  rootRtl_1: {
    "& label": {
      right: 0,
      left: "auto",
      paddingRight: 20,
      fontFamily: FONT_FAMILY
    },
    "& legend": {
      textAlign: "right",
      marginRight: 30,
      fontFamily: FONT_FAMILY
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR
      }
    },
    "& input": {
      fontFamily: FONT_FAMILY
    }
  },
  rootRtl_2: {
    "& label": {
      right: 17,
      left: "auto",
      paddingRight: 12,
      fontFamily: FONT_FAMILY
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25,
      fontFamily: FONT_FAMILY
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR
      }
    },
    "& input": {
      fontFamily: FONT_FAMILY
    }
  },
  rootRtl_4: {
    "& label": {
      right: 17,
      left: "auto",
      paddingRight: 12,
      fontFamily: FONT_FAMILY
    },
    "& legend": {
      textAlign: "right",
      marginRight: 15,
      fontFamily: FONT_FAMILY
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR
      }
    },
    "& input": {
      fontFamily: FONT_FAMILY
    }
  },
  rootRtl_5: {
    "& label": {
      right: 17,
      left: "auto",
      paddingRight: 12,
      fontFamily: FONT_FAMILY
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25,
      fontFamily: FONT_FAMILY
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR
      }
    },
    "& input": {
      fontFamily: FONT_FAMILY
    }
  },
  selectField_rtl: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR
    },
    "& label": {
      right: 0,
      left: "auto",
      fontFamily: FONT_FAMILY
    },
    "& legend": {
      textAlign: "right",
      marginRight: 35,
      fontFamily: FONT_FAMILY
    }
  },

  right: {
    textAlign: "right",
    right: 0,
    left: "auto",
    paddingRight: 40,
    fontFamily: FONT_FAMILY
  },
  button: {
    margin: "10px 10px 0 5px",
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: FONT_FAMILY, // Replace FONT_FAMILY with your custom font
    backgroundColor: MAIN_COLOR, // Replace with your main color
    color: colors.WHITE
  },
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR
      }
    },
    "& input": {
      fontFamily: FONT_FAMILY // Replace FONT_FAMILY with your custom font
    }
  },
  card: {
    borderRadius: "19px",
    backgroundColor: colors.WHITE,
    minHeight: 100,
    maxWidth: "75vw",
    marginTop: 20,
    marginBottom: 20,
    padding: 20,
    boxShadow: `0px 2px 5px ${SECONDORY_COLOR}` // Replace with your secondary color
  },
  submitButtonContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "20px"
  },
  submitButton: {
    marginLeft: "50px"
  }
}));
const EditCarType = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const isRTL = i18n.dir();
  const settings = useSelector((state) => state.settingsdata.settings);
  const cartypes = useSelector((state) => state.cartypes);
  const carlistdata = useSelector((state) => state.carlistdata);
  const staticusers = useSelector((state) => state.usersdata.staticusers);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loding, setLoding] = useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const { editCarType, updateUserCar, editCar } = api;
  const [oldData, setOldData] = useState(null);
  const [data, setData] = useState(null);
  const classes = useStyles();
  const [activeView, setActiveView] = useState("cityRides");
  const [cityRows, setCityRows] = useState([[], []]);
  const [rentalConstants, setRentalConstants] = useState({
    baseFare: "",
    hourlyRate: "",
    kmRate: ""
  });
  const [outstationConstants, setOutstationConstants] = useState({
    baseFare: "",
    perKmRate: "",
    driverAllowance: ""
  });
  const isMidScreen = useMediaQuery("(max-width:1199px)");
  const [cityInputChange, setCityInputChange] = useState(false);

  const addCityRow = (columnIndex) => {
    if (cityRows[columnIndex].length < 5) {
      const newRow = {
        start_interval: "",
        end_interval: "",
        a: "",
        b: "",
        c: ""
      };
      setCityRows((prevRows) =>
        prevRows.map((col, index) =>
          index === columnIndex ? [...col, newRow] : col
        )
      );
    } else {
      alert("You can only create up to 5 rows per main column.");
    }
  };

  const handleCityInputChange = (columnIndex, rowIndex, field, value) => {
    setCityInputChange(true);
    const updatedRows = cityRows.map((col, i) =>
      i === columnIndex
        ? col.map((row, j) =>
            j === rowIndex ? { ...row, [field]: value } : row
          )
        : col
    );
    setCityRows(updatedRows);
  };

  const handleRentalChange = (field, value) => {
    setRentalConstants((prev) => ({ ...prev, [field]: value }));
  };

  const handleOutstationChange = (field, value) => {
    setOutstationConstants((prev) => ({ ...prev, [field]: value }));
  };


  const cityRidesPricingFromTable = () => {
    // Extract pricing data from cityRows cityRows[0] is for normal pricing and cityRows[1] is for surge pricing
    
    // parse string to number in javascript
  // MAX number of float in javascript 
    // let num2 = parseFloat("10.5");
    try {
      // rewrite below logic to parseFloat all values
      const normalPricing = cityRows[0].map((row) => ({
        startInterval: parseFloat(row.start_interval) || 0,
        endInterval: parseFloat(row.end_interval) || 0,
        a: parseFloat(row.a) || 0,
        b: parseFloat(row.b) || 0,
        c: parseFloat(row.c) || 0
      }));
      // rewrite below logic to parseFloat all values
      const surgePricing = cityRows[1].map((row) => ({
        startInterval: parseFloat(row.start_interval) || 0,
        endInterval: parseFloat(row.end_interval) || 0,
        a: parseFloat(row.a) || 0,
        b: parseFloat(row.b) || 0,
        c: parseFloat(row.c) || 0
      }));

      return {
        normal: normalPricing,
        surge: surgePricing
      };
    } catch (error) {
        setCommonAlert({ open: true, msg: "Invalid pricing equations provided" });
    }
    return {
      normal: [],
      surge: []
    }

  };

  useEffect(() => {
    if (id) {
      if (cartypes && cartypes.cars) {
        const carData = cartypes.cars.filter(
          (item) => item.id === id.toString()
        )[0];
        if (!carData) {
          navigate("/404");
        }
        setData(carData);
        setOldData(carData);
      }
    } else {
      setData({
        name: "",
        base_fare: 0,
        convenience_fee_type: "",
        pos: "",
        convenience_fees: 0,
        rate_per_hour: 0,
        extra_info: "",
        min_fare: 0,
        rate_per_unit_distance: 0,
        fleet_admin_fee: 0,
        image:
          "https://cdn.pixabay.com/photo/2012/04/15/22/09/car-35502__480.png",
          cityRidesPricing: { normalPricing: [], surgePricing: [] }
      });
    }
  }, [cartypes, cartypes.cars, id, navigate]);

  // Initialize cityRows with data from carType cityRides pricing
  useEffect(() => {
    if (data && data.cityRidesPricing) {
      const normalPricing = data.cityRidesPricing.normalPricing;
      const surgePricing = data.cityRidesPricing.surgePricing;

      let result = [];

      if (normalPricing !== undefined) {
        const normalRows = normalPricing.map((pricing) => ({
          start_interval: pricing.startInterval,
          end_interval: pricing.endInterval,
          a: pricing.a,
          b: pricing.b,
          c: pricing.c
        }));
        result = [...result, normalRows];
      }
      // check first if surge pricing is available, then only create surge rows
      if (surgePricing !== undefined) {
        const surgeRows = surgePricing.map((pricing) => ({
          start_interval: pricing.startInterval,
          end_interval: pricing.endInterval,
          a: pricing.a,
          b: pricing.b,
          c: pricing.c
        }));
        result = [...result, surgeRows];
      }
      if (result.length === 0) {
        result = [[], []];
      } else if (result.length === 1) {
        result = [result[0], []];
      }
      console.log("oh lala ooh lala", result);
      setCityRows(result);
    }
  }, [data]);

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  const handleInputToNumberChange = (e) => {
    setData({ ...data, [e.target.id]: Number(e.target.value) });
  };

  const handleExtraInfoChange = (e) => {
    setData({ ...data, extra_info: e.target.value });
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };
  const handleSubmit = () => {
    const { normal: normalPricing, surge: surgePricing } =
      cityRidesPricingFromTable();
    if (normalPricing.length === 0 || surgePricing.length === 0) {
      setCommonAlert({ open: true, msg: "Please provide pricing equations" });
      return;
    } 

    if (!id) {
      if (
        !(
          data &&
          data.name &&
          data.pos 
          // data.convenience_fee_type &&
          // data.base_fare &&
          // data.min_fare &&
          // data.rate_per_hour &&
          // data.rate_per_unit_distance
        )
      ) {
        if (!(data && data.name)) {
          setCommonAlert({ open: true, msg: t("proper_input_name") });
        } else if (!data.pos) {
          setCommonAlert({ open: true, msg: t("position_required") });
        } 
        
        // else if (!data.base_fare) {
        //   setCommonAlert({ open: true, msg: t("base_fare_required") });
        // } else if (!data.min_fare) {
        //   setCommonAlert({ open: true, msg: t("min_fare_required") });
        // } else if (!data.rate_per_hour) {
        //   setCommonAlert({ open: true, msg: t("rate_per_hour_required") });
        // } else if (!data.rate_per_unit_distance) {
        //   setCommonAlert({
        //     open: true,
        //     msg: t("rate_per_unit_distance_required")
        //   });
        // } else if (!data.convenience_fee_type) {
        //   setCommonAlert({
        //     open: true,
        //     msg: t("convenience_fee_type_required")
        //   });
        // }
      } else {
        settings.AllowCriticalEditsAdmin
          ? new Promise((resolve, reject) => {
              setLoding(true);
              setTimeout(() => {
                if (data && data.name) {
                  data["createdAt"] = new Date().getTime();
                  data["cityRidesPricing"] = { normalPricing, surgePricing };
                  dispatch(editCarType(data, "Add"));
                  resolve();
                  setLoding(false);
                  navigate("/cartypes");
                } else {
                  setCommonAlert({ open: true, msg: t("proper_input_name") });
                  reject(new Error("Enter proper name"));
                  setLoding(false);
                }
              }, 600);
            })
          : new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                setCommonAlert({ open: true, msg: t("demo_mode") });
              }, 600);
            });
      }
    } else if (id) {
      if (
        !(
          data &&
          data.name &&
          data.pos 
          // data.convenience_fee_type &&
          // data.base_fare &&
          // data.min_fare &&
          // data.rate_per_hour &&
          // data.rate_per_unit_distance
        )
      ) {
        if (!(data && data.name)) {
          setCommonAlert({ open: true, msg: t("proper_input_name") });
        } else if (!data.pos) {
          setCommonAlert({ open: true, msg: t("position_required") });
        } 
        
        // else if (!data.base_fare) {
        //   setCommonAlert({ open: true, msg: t("base_fare_required") });
        // } else if (!data.min_fare) {
        //   setCommonAlert({ open: true, msg: t("min_fare_required") });
        // } else if (!data.rate_per_hour) {
        //   setCommonAlert({ open: true, msg: t("rate_per_hour_required") });
        // } else if (!data.rate_per_unit_distance) {
        //   setCommonAlert({
        //     open: true,
        //     msg: t("rate_per_unit_distance_required")
        //   });
        // } else if (!data.convenience_fee_type) {
        //   setCommonAlert({
        //     open: true,
        //     msg: t("convenience_fee_type_required")
        //   });
        // }

      } else {
        settings.AllowCriticalEditsAdmin
          ? new Promise((resolve) => {
              setLoding(true);
              setTimeout(() => {
                resolve();
                if (data !== oldData || cityInputChange === true) {
                  delete data.tableData;
                  if (data.name !== oldData.name) {
                    let users = staticusers?.filter(
                      (user) =>
                        user.usertype === "driver" &&
                        user.carType === oldData.name
                    );
                    for (let i = 0; i < users?.length; i++) {
                      dispatch(
                        updateUserCar(users[i].id, {
                          carType: data.name
                        })
                      );
                    }
                    let cars = carlistdata.cars.filter(
                      (car) => car.carType === oldData.name
                    );
                    for (let i = 0; i < cars.length; i++) {
                      dispatch(
                        editCar({ ...cars[i], carType: data.name }, "Update")
                      );
                    }
                  }
                  data["cityRidesPricing"] = { normalPricing, surgePricing };
                  dispatch(editCarType(data, "Update"));
                  navigate("/cartypes");
                  setLoding(false);
                } else {
                  setLoding(false);
                  setCommonAlert({
                    open: true,
                    msg: t("make_changes_to_update")
                  });
                }
              }, 600);
            })
          : new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                alert(t("demo_mode"));
              }, 600);
            });
      }
    }
  };

  const handleChangeConvenienceFeeType = (e) => {
    setData({ ...data, convenience_fee_type: e.target.value });
  };
  return loding ? (
    <CircularLoading />
  ) : (
    <div>
      <Card
        style={{
          borderRadius: "19px",
          backgroundColor: colors.WHITE,
          minHeight: 100,
          maxWidth: "75vw",
          marginTop: 20,
          marginBottom: 20,
          padding: 25,
          boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`
        }}
      >
        <Typography
          variant="h5"
          style={{
            margin: "10px 10px 0 5px",
            textAlign: isRTL === "rtl" ? "right" : "left",
            fontFamily: FONT_FAMILY
          }}
        >
          {id ? t("update_carType_title") : t("add_carType_title")}
        </Typography>
        <div
          dir={isRTL === "rtl" ? "rtl" : "ltr"}
          style={{
            marginBottom: 20
          }}
        >
          <Button
            variant="text"
            onClick={() => {
              navigate("/cartypes");
            }}
          >
            <Typography
              style={{
                margin: "10px 10px 0 5px",
                textAlign: isRTL === "rtl" ? "right" : "left",
                fontWeight: "bold",
                color: MAIN_COLOR,
                fontFamily: FONT_FAMILY
              }}
            >
              {`<<- ${t("go_back")}`}
            </Typography>
          </Button>
        </div>
        <Grid
          container
          spacing={2}
          sx={{
            gridTemplateColumns: "50%",
            direction: isRTL === "rtl" ? "rtl" : "ltr"
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              InputLabelProps={{ style: { fontFamily: FONT_FAMILY } }}
              label={t("name")}
              id="name"
              value={data?.name || ""}
              fullWidth
              onChange={handleInputChange}
              className={isRTL === "rtl" ? classes.rootRtl : classes.textField}
            />
          </Grid>
{/* 
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              InputLabelProps={{ style: { fontFamily: FONT_FAMILY } }}
              label={t("base_fare")}
              id="base_fare"
              value={data?.base_fare || 0}
              fullWidth
              type="number"
              onChange={handleInputToNumberChange}
              className={
                isRTL === "rtl" ? classes.rootRtl_3 : classes.textField
              }
            />
          </Grid> */}
          {/* <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <FormControl
              fullWidth
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            >
              <InputLabel
                id="demo-simple-select-label"
                className={isRTL === "rtl" ? classes.right : ""}
                sx={{ "&.Mui-focused": { color: MAIN_COLOR } }}
              >
                {
                  <Typography className={classes.typography}>
                    {t("convenience_fee_type")}
                  </Typography>
                }
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="convenience_fee_type"
                value={data?.convenience_fee_type || ""}
                label={t("convenience_fee_type")}
                onChange={handleChangeConvenienceFeeType}
                className={
                  isRTL === "rtl"
                    ? classes.selectField_rtl
                    : classes.selectField
                }
              >
                <MenuItem
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                  value={"flat"}
                >
                  {
                    <Typography className={classes.typography}>
                      {t("flat")}
                    </Typography>
                  }
                </MenuItem>
                <MenuItem
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                  value={"percentage"}
                >
                  {
                    <Typography className={classes.typography}>
                      {t("percentage")}
                    </Typography>
                  }
                </MenuItem>
              </Select>
            </FormControl>
          </Grid> */}

          {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              InputLabelProps={{ style: { fontFamily: FONT_FAMILY } }}
              label={t("convenience_fee")}
              id="convenience_fees"
              value={data?.convenience_fees || 0}
              type="number"
              fullWidth
              onChange={handleInputToNumberChange}
              className={
                isRTL === "rtl" ? classes.rootRtl_4 : classes.textField
              }
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              InputLabelProps={{ style: { fontFamily: FONT_FAMILY } }}
              label={t("rate_per_hour")}
              id="rate_per_hour"
              value={data?.rate_per_hour || 0}
              type="number"
              fullWidth
              onChange={handleInputToNumberChange}
              className={
                isRTL === "rtl" ? classes.rootRtl_3 : classes.textField
              }
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              InputLabelProps={{ style: { fontFamily: FONT_FAMILY } }}
              label={t("min_fare")}
              id="min_fare"
              value={data?.min_fare || 0}
              type="number"
              fullWidth
              onChange={handleInputToNumberChange}
              className={
                isRTL === "rtl" ? classes.rootRtl_2 : classes.textField
              }
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              InputLabelProps={{ style: { fontFamily: FONT_FAMILY } }}
              label={t("rate_per_unit_distance")}
              id="rate_per_unit_distance"
              value={data?.rate_per_unit_distance || 0}
              type="number"
              fullWidth
              onChange={handleInputToNumberChange}
              className={
                isRTL === "rtl" ? classes.rootRtl_1 : classes.textField
              }
            />
          </Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              InputLabelProps={{ style: { fontFamily: FONT_FAMILY } }}
              label={t("position")}
              id="pos"
              value={data?.pos || 0}
              type="number"
              fullWidth
              onChange={handleInputToNumberChange}
              className={
                isRTL === "rtl" ? classes.rootRtl_4 : classes.textField
              }
            />
          </Grid>

          {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              InputLabelProps={{ style: { fontFamily: FONT_FAMILY } }}
              label={t("fleet_admin_fee")}
              id="fleet_admin_fee"
              value={data?.fleet_admin_fee || 0}
              type="number"
              fullWidth
              onChange={handleInputToNumberChange}
              className={
                isRTL === "rtl" ? classes.rootRtl_5 : classes.textField
              }
            />
          </Grid> */}

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              InputLabelProps={{ style: { fontFamily: FONT_FAMILY } }}
              label={t("extra_info")}
              id="extra_info"
              value={data?.extra_info || ""}
              fullWidth
              onChange={handleExtraInfoChange}
              className={
                isRTL === "rtl" ? classes.rootRtl_3 : classes.textField
              }
            />
          </Grid>

          <div
            className="menu"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
              // backgroundColor: 'pink',
              flex: 1,
              padding: '20px'
            }}
          >
            {["cityRides", "rental", "outstation"].map((view) => (
              <Button
                key={view}
                className={classes.button}
                variant={activeView === view ? "contained" : "outlined"}
                onClick={() => setActiveView(view)}
                style={{
                  marginRight: "10px",
                  color: activeView === view ? colors.WHITE : colors.BLACK,
                  backgroundColor:
                    activeView === view ? MAIN_COLOR : colors.WHITE
                }}
              >
                {view.charAt(0).toUpperCase() + view.slice(1)}
              </Button>
            ))}
          </div>

          {activeView === "cityRides" && (
            <Grid
              container
              spacing={2}
              // style={{ marginTop: "20px" }}
            >
              {Array.from({ length: 2 }).map((_, columnIndex) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    key={columnIndex}
                    style={
                      {
                        // border: "1px solid #D9D9D9",
                        // padding: "10px"
                      }
                    }
                  >
                    <Typography variant="h6">
                      {columnIndex === 0 ? `Normal Fare` : `Surge Fare`}{" "}
                    </Typography>
                    {cityRows[columnIndex].map((row, rowIndex) => {
                      return (
                        <div
                          key={rowIndex}
                          style={{
                            marginBottom: "10px",
                            border: "solid 0.5px gray",
                            padding: "15px",
                            paddingTop: "5px"
                          }}
                        >
                          <h6>Equation {`${rowIndex + 1}`} </h6>
                          <TextField
                            className={classes.textField}
                            label="Start Interval"
                            value={row.start_interval}
                            onChange={(e) =>
                              handleCityInputChange(
                                columnIndex,
                                rowIndex,
                                "start_interval",
                                e.target.value
                              )
                            }
                            fullWidth
                            variant="outlined"
                            type="number"
                          />
                          <TextField
                            className={classes.textField}
                            label="End Interval"
                            value={row.end_interval}
                            onChange={(e) =>
                              handleCityInputChange(
                                columnIndex,
                                rowIndex,
                                "end_interval",
                                e.target.value
                              )
                            }
                            fullWidth
                            variant="outlined"
                            style={{ marginTop: "10px" }}
                            type="number"
                          />
                          <TextField
                            className={classes.textField}
                            label="A"
                            value={row.a}
                            onChange={(e) =>
                              handleCityInputChange(
                                columnIndex,
                                rowIndex,
                                "a",
                                e.target.value
                              )
                            }
                            fullWidth
                            variant="outlined"
                            style={{ marginTop: "10px" }}
                            type="number"
                          />
                          <TextField
                            className={classes.textField}
                            label="B"
                            value={row.b}
                            onChange={(e) =>
                              handleCityInputChange(
                                columnIndex,
                                rowIndex,
                                "b",
                                e.target.value
                              )
                            }
                            fullWidth
                            variant="outlined"
                            style={{ marginTop: "10px" }}
                            type="number"
                          />
                          <TextField
                            className={classes.textField}
                            label="C"
                            value={row.c}
                            onChange={(e) =>
                              handleCityInputChange(
                                columnIndex,
                                rowIndex,
                                "c",
                                e.target.value
                              )
                            }
                            fullWidth
                            variant="outlined"
                            style={{ marginTop: "10px" }}
                            type="number"
                          />
                        </div>
                      );
                    })}

                    <Button
                      style={{
                        borderRadius: "19px",
                        backgroundColor: MAIN_COLOR,
                        minHeight: 50,
                        minWidth: "50%",
                        textAlign: "center",
                        boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`
                      }}
                      onClick={() => addCityRow(columnIndex)}
                      variant="contained"
                    >
                      <Typography
                        style={{
                          color: colors.WHITE,
                          textAlign: "center",
                          fontSize: 16,
                          fontWeight: "bold",
                          fontFamily: FONT_FAMILY
                        }}
                      >
                        Add Row
                      </Typography>
                    </Button>

                    {/* <Button
                      className={classes.button}
                      variant="outlined"
                      fullWidth
                      onClick={() => addCityRow(columnIndex)}
                    >
                      Add Row
                    </Button> */}
                  </Grid>
                );
              })}
            </Grid>
          )}

          {activeView === "rental" && (
            <>
              <TextField
                className={classes.textField}
                label="Base Fare"
                value={rentalConstants.baseFare}
                onChange={(e) => handleRentalChange("baseFare", e.target.value)}
                fullWidth
                variant="outlined"
                style={{ marginBottom: "10px" }}
              />
              <TextField
                className={classes.textField}
                label="Hourly Rate"
                value={rentalConstants.hourlyRate}
                onChange={(e) =>
                  handleRentalChange("hourlyRate", e.target.value)
                }
                fullWidth
                variant="outlined"
                style={{ marginBottom: "10px" }}
              />
              <TextField
                className={classes.textField}
                label="Per Km Rate"
                value={rentalConstants.kmRate}
                onChange={(e) => handleRentalChange("kmRate", e.target.value)}
                fullWidth
                variant="outlined"
                style={{ marginBottom: "10px" }}
              />
              {/* <Button
                className={classes.button}
                variant="contained"
                onClick={() => handleSubmit("rental")}
                style={{ marginTop: "20px" }}
              >
                Submit Rental Constants
              </Button> */}
            </>
          )}

          {activeView === "outstation" && (
            <>
              <TextField
                className={classes.textField}
                label="Base Fare"
                value={outstationConstants.baseFare}
                onChange={(e) =>
                  handleOutstationChange("baseFare", e.target.value)
                }
                fullWidth
                variant="outlined"
                style={{ marginBottom: "10px" }}
              />
              <TextField
                className={classes.textField}
                label="Per Km Rate"
                value={outstationConstants.perKmRate}
                onChange={(e) =>
                  handleOutstationChange("perKmRate", e.target.value)
                }
                fullWidth
                variant="outlined"
                style={{ marginBottom: "10px" }}
              />
              <TextField
                className={classes.textField}
                label="Driver Allowance"
                value={outstationConstants.driverAllowance}
                onChange={(e) =>
                  handleOutstationChange("driverAllowance", e.target.value)
                }
                fullWidth
                variant="outlined"
                style={{ marginBottom: "10px" }}
              />
              {/* <Button
                className={classes.button}
                variant="contained"
                onClick={() => handleSubmit("outstation")}
                style={{ marginTop: "20px" }}
              >
                Submit Outstation Constants
              </Button> */}
            </>
          )}

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              style={{
                borderRadius: "19px",
                backgroundColor: MAIN_COLOR,
                minHeight: 50,
                minWidth: "50%",
                textAlign: "center",
                boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`
              }}
              onClick={handleSubmit}
              variant="contained"
            >
              <Typography
                style={{
                  color: colors.WHITE,
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: "bold",
                  fontFamily: FONT_FAMILY
                }}
              >
                {id ? t("update") : t("submit")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Card>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
};

export default EditCarType;

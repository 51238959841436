import React, { useState, useEffect } from "react";
import AlertDialog from "../components/AlertDialog";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  useMediaQuery
} from "@mui/material";
import { api } from "common";
import { useTranslation } from "react-i18next";
import CircularLoading from "components/CircularLoading";
import { colors } from "components/Theme/WebTheme";
import {
  MAIN_COLOR,
  SECONDORY_COLOR,
  FONT_FAMILY
} from "../common/sharedFunctions";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  typography: {
    fontFamily: FONT_FAMILY
  },
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR
      }
    },
    "& input": {
      fontFamily: FONT_FAMILY
    }
  },
  selectField: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR
    }
  },
  rootRtl: {
    "& label": {
      right: 0,
      left: "auto",
      paddingRight: 20,
      fontFamily: FONT_FAMILY
    },
    "& legend": {
      textAlign: "right",
      marginRight: 37,
      fontFamily: FONT_FAMILY
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR
      }
    },
    "& input": {
      fontFamily: FONT_FAMILY
    }
  },
  selectField_rtl: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR
    },
    "& label": {
      right: 50,
      left: "auto",
      paddingRight: 12,
      fontFamily: FONT_FAMILY
    },
    "& legend": {
      textAlign: "right",
      marginRight: 15,
      fontFamily: FONT_FAMILY
    }
  },
  right: {
    textAlign: "right",
    right: 0,
    left: "auto",
    paddingRight: 40,
    fontFamily: FONT_FAMILY
  }
}));

const EditCar = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector((state) => state.settingsdata.settings);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { editCar } = api;
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  // const [driversObj, setDriversObj] = useState({});
  const [fleetMapObj, setFleetMapObj] = useState({});
  const [role, setRole] = useState(null);
  const userdata = useSelector((state) => state.usersdata);
  // const [drivers, setDrivers] = useState([]);
  const cartypes = useSelector((state) => state.cartypes);
  const carlistdata = useSelector((state) => state.carlistdata);
  const [data, setData] = useState(null);
  const [oldData, setOldData] = useState(null);
  const [carTypeAvailable, setCarTypeAvailable] = useState(null);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const isMidScreen = useMediaQuery("(max-width:1199px)");

  useEffect(() => {
    if (id) {
      if (carlistdata && carlistdata.cars) {
        const carData = carlistdata.cars.find(
          (item) => item.id === id.toString()
        );
        if (!carData) {
          navigate("/404");
        }
        setData(carData);
        setOldData(carData);
      }
    } else {
      setData({
        createdAt: new Date().getTime(),
        car_image:
          "https://cdn.pixabay.com/photo/2012/04/13/20/37/car-33556__480.png",
        vehicleNumber: "",
        vehicleMake: "",
        carType: "",
        // driver:
        //   auth && auth.profile.usertype === "driver" ? auth.profile.uid : "",
        vehicleModel: "",
        other_info: ""
      });
    }
  }, [carlistdata, id, navigate, auth]);

  useEffect(() => {
    const checkCar = cartypes?.cars.find(
      (item) => item.name === oldData?.carType
    );
    setCarTypeAvailable(!!checkCar);
  }, [cartypes, oldData]);

  useEffect(() => {
    if (role !== "driver" && userdata.users) {
      const arr = userdata.users.filter(
        (user) =>
          user.usertype === "driver" &&
          ((role === "fleetadmin" &&
            user.fleetadmin &&
            user.fleetadmin === auth.profile.uid) ||
            role === "admin")
      );
      const obj = {};
      const obj2 = {};
      const arr2 = arr.map((user) => {
        const desc = `${user.firstName} ${user.lastName} (${
          settings.AllowCriticalEditsAdmin ? user.mobile : t("hidden_demo")
        }) ${settings.AllowCriticalEditsAdmin ? user.email : t("hidden_demo")}`;
        obj[user.id] = desc;
        obj2[user.id] = user.fleetadmin ? user.fleetadmin : null;
        return { id: user.id, desc };
      });
      // setDrivers(arr2);
      // setDriversObj(obj);
      setFleetMapObj(obj2);
    }
  }, [
    userdata.users,
    settings.AllowCriticalEditsAdmin,
    role,
    auth.profile.uid,
    t
  ]);

  useEffect(() => {
    if (auth.profile && auth.profile.usertype) {
      setRole(auth.profile.usertype);
    }
  }, [auth.profile]);

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  // const handleChangeDriver = (event) => {
  //   setData({ ...data, driver: event.target.value });
  // };

  const handleChangeCarType = (event) => {
    setData({ ...data, carType: event.target.value });
  };

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  const updateCar = () => {
    if (settings.AllowCriticalEditsAdmin) {
      if (JSON.stringify(data) !== JSON.stringify(oldData)) {
        const updatedData = {
          ...data,
          // fleetadmin: data.driver ? fleetMapObj[data.driver] || null : null
        };
        dispatch(editCar(updatedData, "Update"));
        navigate("/cars");
      } else {
        setCommonAlert({ open: true, msg: t("make_changes_to_update") });
      }
    } else {
      setCommonAlert({ open: true, msg: t("demo_mode") });
    }
  };

  const addCar = () => {
    if (settings.AllowCriticalEditsAdmin) {
      if (!data.vehicleNumber) {
        setCommonAlert({ open: true, msg: t("car_no_not_found") });
      } else if (!data.vehicleMake) {
        setCommonAlert({ open: true, msg: t("vehicleMake_required") });
      } else if (!data.vehicleModel) {
        setCommonAlert({ open: true, msg: t("vehicleModel_required") });
      } 
      // else if (!data.driver) {
      //   setCommonAlert({ open: true, msg: t("driver_required") });
      // } 
      
      else if (!data.carType) {
        setCommonAlert({ open: true, msg: t("carType_required") });
      } else {
        const newCarData = {
          ...data,
          createdAt: new Date().getTime(),
          // fleetadmin: data.driver ? fleetMapObj[data.driver] || null : null,
          approved: !settings.carApproval
        };
        dispatch(editCar(newCarData, "Add"));
        navigate("/cars");
      }
    } else {
      setCommonAlert({ open: true, msg: t("demo_mode") });
    }
  };

  return loading ? (
    <CircularLoading />
  ) : (
    <>
      <div>
        <Card
          style={{
            borderRadius: "19px",
            backgroundColor: colors.WHITE,
            minHeight: 100,
            maxWidth: "75vw",
            marginTop: 20,
            marginBottom: 20,
            padding: 20,
            boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`
          }}
        >
          <Typography
            variant="h5"
            style={{
              margin: "10px 10px 0 5px",
              textAlign: isRTL === "rtl" ? "right" : "left",
              fontFamily: FONT_FAMILY
            }}
          >
            {id ? t("update_car_title") : t("add_car_title")}
          </Typography>
          <div dir={isRTL === "rtl" ? "rtl" : "ltr"}>
            <Button
              variant="text"
              onClick={() => {
                navigate("/cars");
              }}
            >
              <Typography
                style={{
                  margin: "10px 10px 0 5px",
                  textAlign: isRTL === "rtl" ? "right" : "left",
                  fontWeight: "bold",
                  color: MAIN_COLOR,
                  fontFamily: FONT_FAMILY
                }}
              >
                {`<<- ${t("go_back")}`}
              </Typography>
            </Button>
          </div>
          <Grid
            container
            spacing={2}
            sx={{
              gridTemplateColumns: "50%",
              rowGap: "20px",
              marginY: 1,
              direction: isRTL === "rtl" ? "rtl" : "ltr"
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <TextField
                InputLabelProps={{ style: { fontFamily: FONT_FAMILY } }}
                label={t("vehicle_reg_no")}
                id="vehicleNumber"
                value={data?.vehicleNumber || ""}
                fullWidth
                onChange={handleInputChange}
                className={
                  isRTL === "rtl" ? classes.rootRtl : classes.textField
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <TextField
                InputLabelProps={{ style: { fontFamily: FONT_FAMILY } }}
                label={t("vehicle_model_name")}
                id="vehicleMake"
                value={data?.vehicleMake || ""}
                fullWidth
                onChange={handleInputChange}
                className={
                  isRTL === "rtl" ? classes.rootRtl : classes.textField
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <TextField
                InputLabelProps={{ style: { fontFamily: FONT_FAMILY } }}
                label={t("vehicle_model_no")}
                id="vehicleModel"
                value={data?.vehicleModel || ""}
                fullWidth
                onChange={handleInputChange}
                className={
                  isRTL === "rtl" ? classes.rootRtl : classes.textField
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <TextField
                InputLabelProps={{ style: { fontFamily: FONT_FAMILY } }}
                label={t("other_info")}
                id="other_info"
                value={data?.other_info || ""}
                fullWidth
                onChange={handleInputChange}
                className={
                  isRTL === "rtl" ? classes.rootRtl : classes.textField
                }
              />
            </Grid>
            {/* <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <FormControl
                fullWidth
                style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
              >
                <InputLabel
                  id="driver-select-label"
                  className={isRTL === "rtl" ? classes.right : ""}
                  sx={{ "&.Mui-focused": { color: MAIN_COLOR } }}
                >
                  <Typography className={classes.typography}>
                    {t("driver")}
                  </Typography>
                </InputLabel>
                <Select
                  labelId="driver-select-label"
                  id="driver-select"
                  value={
                    role === "driver" ? auth.profile.uid : data?.driver || ""
                  }
                  disabled={role === "driver"}
                  label={t("driver")}
                  onChange={handleChangeDriver}
                  className={
                    isRTL === "rtl"
                      ? classes.selectField_rtl
                      : classes.selectField
                  }
                >
                  {role === "admin" || role === "fleetadmin"
                    ? drivers.map((driver) => (
                        <MenuItem
                          key={driver.id}
                          value={driver.id}
                          style={{
                            direction: isRTL === "rtl" ? "rtl" : "ltr"
                          }}
                        >
                          <Typography className={classes.typography}>
                            {driver.desc}
                          </Typography>
                        </MenuItem>
                      ))
                    : role === "driver" && (
                        <MenuItem
                          value={auth.profile.uid}
                          style={{
                            direction: isRTL === "rtl" ? "rtl" : "ltr"
                          }}
                        >
                          <Typography className={classes.typography}>
                            {`${auth.profile.firstName} ${auth.profile.lastName} (${
                              settings.AllowCriticalEditsAdmin
                                ? auth.profile.mobile
                                : t("hidden_demo")
                            }) ${
                              settings.AllowCriticalEditsAdmin
                                ? auth.profile.email
                                : t("hidden_demo")
                            }`}
                          </Typography>
                        </MenuItem>
                      )}
                </Select>
              </FormControl>
            </Grid> */}

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <FormControl
                fullWidth
                style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
              >
                <InputLabel
                  id="car-type-select-label"
                  className={isRTL === "rtl" ? classes.right : ""}
                  sx={{ "&.Mui-focused": { color: MAIN_COLOR } }}
                >
                  <Typography className={classes.typography}>
                    {t("car_type")}
                  </Typography>
                </InputLabel>
                <Select
                  labelId="car-type-select-label"
                  id="car-type-select"
                  value={data?.carType || ""}
                  label={t("car_type")}
                  onChange={handleChangeCarType}
                  className={
                    isRTL === "rtl"
                      ? classes.selectField_rtl
                      : classes.selectField
                  }
                >
                  {!carTypeAvailable && oldData?.carType && (
                    <MenuItem
                      value={oldData.carType}
                      style={{
                        direction: isRTL === "rtl" ? "rtl" : "ltr"
                      }}
                    >
                      <Typography className={classes.typography}>
                        {oldData.carType}
                      </Typography>
                    </MenuItem>
                  )}
                  {cartypes?.cars?.map((type) => (
                    <MenuItem
                      key={type.id}
                      value={type.name}
                      style={{
                        direction: isRTL === "rtl" ? "rtl" : "ltr"
                      }}
                    >
                      <Typography className={classes.typography}>
                        {type.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                style={{
                  borderRadius: "19px",
                  backgroundColor: MAIN_COLOR,
                  minHeight: 50,
                  width: isMidScreen ? "100%" : "50%",
                  textAlign: "center"
                }}
                onClick={() => {
                  if (id) {
                    updateCar();
                  } else {
                    addCar();
                  }
                }}
                variant="contained"
              >
                <Typography
                  style={{
                    color: colors.WHITE,
                    textAlign: "center",
                    fontSize: 16,
                    fontWeight: "bold",
                    fontFamily: FONT_FAMILY
                  }}
                >
                  {id ? t("update") : t("add")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Card>
        <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
          {commonAlert.msg}
        </AlertDialog>
      </div>
    </>
  );
};

export default EditCar;

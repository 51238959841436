import {
    FETCH_CARS,
    FETCH_CARS_SUCCESS,
    FETCH_CARS_FAILED,
    EDIT_CAR
  } from "../store/types";
  import store from '../store/store';
  import { firebase } from '../config/configureFirebase';
  import { onValue, update, set, child, remove, push } from "firebase/database";
  import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
  
  export const fetchCars = () => (dispatch) => {
  
    const {
        carsRef
    } = firebase;
  
    dispatch({
      type: FETCH_CARS,
      payload: null
    });

    const userInfo = store.getState().auth.profile;

    onValue(carsRef(userInfo.uid, userInfo.usertype), snapshot => {
      if (snapshot.val()) {
        let data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        dispatch({
          type: FETCH_CARS_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_CARS_FAILED,
          payload: store.getState().languagedata.defaultLanguage.no_cars
        });
      }
    });
  };
  
 // Action for editing a car
export const editCar = (car, method) => async (dispatch) => {
  const {
    carAddRef,
    carEditRef,
    carImage
  } = firebase;

  dispatch({
    type: EDIT_CAR,
    payload: { method, car }
  });

  if (method === 'Add') {
    push(carAddRef, car);
  } else if (method === 'Delete') {
    remove(carEditRef(car.id));
  } else if (method === 'UpdateImage') {
    await uploadBytesResumable(carImage(car.id), car.car_image);
    let image = await getDownloadURL(carImage(car.id));
    let data = { ...car, car_image: image };
    set(carEditRef(car.id), data);
  } else {
    set(carEditRef(car.id), car);
  }
};

// Action for updating a user's car with an image
export const updateUserCarWithImage = (newData, blob) => (dispatch) => {
  const {
    carAddRef,
    carImage
  } = firebase;

  var carId = push(carAddRef).key;

  uploadBytesResumable(carImage(carId), blob).then(() => {
    blob.close();
    return getDownloadURL(carImage(carId));
  }).then((url) => {
    newData.car_image = url;
    set(child(carAddRef, carId), newData);
  });
};

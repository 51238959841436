export const FirebaseConfig = {
	"projectId": "saurabh-chandra-automotive",
	"appId": "1:749483792038:web:8ad137ddb8ec5c72e10667",
	"databaseURL": "https://saurabh-chandra-automotive-default-rtdb.asia-southeast1.firebasedatabase.app",
	"storageBucket": "saurabh-chandra-automotive.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyC6BNSG3gxxMzjy5uT99ZxzySMNSjIpl4I",
	"authDomain": "saurabh-chandra-automotive.firebaseapp.com",
	"messagingSenderId": "749483792038",
	"measurementId": "G-XWS6T0LJBG"
};

import {
  MAIN_COLOR,
  SECONDORY_COLOR,
  FONT_FAMILY
} from "../common/sharedFunctions";
import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Grid,
  Card,
  useMediaQuery
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { api } from "common"; // Modify according to your project structure
import { colors } from "components/Theme/WebTheme"; // Assuming you have a theme file

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "10px 10px 0 5px",
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: FONT_FAMILY, // Replace FONT_FAMILY with your custom font
    backgroundColor: MAIN_COLOR, // Replace with your main color
    color: colors.WHITE
  },
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR
      }
    },
    "& input": {
      fontFamily: FONT_FAMILY // Replace FONT_FAMILY with your custom font
    }
  },
  card: {
    borderRadius: "19px",
    backgroundColor: colors.WHITE,
    minHeight: 100,
    maxWidth: "75vw",
    marginTop: 20,
    marginBottom: 20,
    padding: 20,
    boxShadow: `0px 2px 5px ${SECONDORY_COLOR}` // Replace with your secondary color
  },
  submitButtonContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "20px"
  },
  submitButton: {
    marginLeft: "50px"
  }
}));

export default function FareCalculator() {

  const [activeView, setActiveView] = useState("cityRides");
  const [cityRows, setCityRows] = useState([[], [], [], [], [], []]);
  const [rentalConstants, setRentalConstants] = useState({
    baseFare: "",
    hourlyRate: "",
    kmRate: ""
  });
  const [outstationConstants, setOutstationConstants] = useState({
    baseFare: "",
    perKmRate: "",
    driverAllowance: ""
  });

  const dispatch = useDispatch();
  const { editCarType } = api; // Assuming you have an editCarType function in your API
  const classes = useStyles();
  const isMidScreen = useMediaQuery("(max-width:1199px)");
  const [data, setData] = useState([]);
  const cartypes = useSelector((state) => state.cartypes);

  useEffect(() => {
    if (cartypes.cars) {
      console.log(JSON.stringify(cartypes.cars, null, 2));
      setData(cartypes.cars);
    } else {
      setData([]);
    }
  }, [cartypes.cars]);

  const addCityRow = (columnIndex) => {
    if (cityRows[columnIndex].length < 5) {
      const newRow = {
        start_interval: "",
        end_interval: "",
        a: "",
        b: "",
        c: ""
      };
      setCityRows((prevRows) =>
        prevRows.map((col, index) =>
          index === columnIndex ? [...col, newRow] : col
        )
      );
    } else {
      alert("You can only create up to 5 rows per main column.");
    }
  };

  const handleCityInputChange = (columnIndex, rowIndex, field, value) => {
    const updatedRows = cityRows.map((col, i) =>
      i === columnIndex
        ? col.map((row, j) =>
            j === rowIndex ? { ...row, [field]: value } : row
          )
        : col
    );
    setCityRows(updatedRows);
  };

  const handleRentalChange = (field, value) => {
    setRentalConstants((prev) => ({ ...prev, [field]: value }));
  };

  const handleOutstationChange = (field, value) => {
    setOutstationConstants((prev) => ({ ...prev, [field]: value }));
  };

  const submitCarType = (carTypeId, pricingData) => {
    const carTypeToUpdate = {
      id: carTypeId,
      cityRidesPricing: pricingData
      // ... other carType details
    };

    dispatch(editCarType(carTypeToUpdate, "Update"))
      .then(() => {
        alert(`Car Type ${carTypeId} data updated successfully!`);
      })
      .catch((error) => {
        console.error(`Error updating Car Type ${carTypeId} data:`, error);
        alert(`Failed to update Car Type ${carTypeId} data.`);
      });
  };

  const handleSubmit = (carType) => {
    const normalPricing = {
      startInterval:
        cityRows[carType === "A" ? 0 : carType === "B" ? 2 : 4][0]
          ?.start_interval || 0,
      endInterval:
        cityRows[carType === "A" ? 0 : carType === "B" ? 2 : 4][0]
          ?.end_interval || 0,
      a: cityRows[carType === "A" ? 0 : carType === "B" ? 2 : 4][0]?.a || 0,
      b: cityRows[carType === "A" ? 0 : carType === "B" ? 2 : 4][0]?.b || 0,
      c: cityRows[carType === "A" ? 0 : carType === "B" ? 2 : 4][0]?.c || 0
    };

    const surgePricing = {
      startInterval:
        cityRows[carType === "A" ? 1 : carType === "B" ? 3 : 5][0]
          ?.start_interval || 0,
      endInterval:
        cityRows[carType === "A" ? 1 : carType === "B" ? 3 : 5][0]
          ?.end_interval || 0,
      a: cityRows[carType === "A" ? 1 : carType === "B" ? 3 : 5][0]?.a || 0,
      b: cityRows[carType === "A" ? 1 : carType === "B" ? 3 : 5][0]?.b || 0,
      c: cityRows[carType === "A" ? 1 : carType === "B" ? 3 : 5][0]?.c || 0
    };

    const pricingData = {
      normal: normalPricing,
      surge: surgePricing
    };

    submitCarType(carType, pricingData);
  };

  return (
    <Card className={classes.card}>
      <Typography
        variant="h5"
        style={{
          margin: "10px 10px 0 5px",
          textAlign: "left",
          fontFamily: "FONT_FAMILY" // Replace with your custom font
        }}
      >
        Fare Calculation -{" "}
        {activeView.charAt(0).toUpperCase() + activeView.slice(1)}
      </Typography>

      <div
        className="menu"
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px"
        }}
      >
        {["cityRides", "rental", "outstation"].map((view) => (
          <Button
            key={view}
            className={classes.button}
            variant={activeView === view ? "contained" : "outlined"}
            onClick={() => setActiveView(view)}
            style={{
              marginRight: "10px",
              color: activeView === view ? colors.WHITE : colors.BLACK,
              backgroundColor: activeView === view ? MAIN_COLOR : colors.WHITE
            }}
          >
            {view.charAt(0).toUpperCase() + view.slice(1)}
          </Button>
        ))}
      </div>

      {activeView === "cityRides" && (
        <Grid container spacing={2} style={{marginTop: '20px'}} >
          {Array.from({ length: 6 }).map((_, columnIndex) => {
            // Determine the background color based on the column index
            let backgroundColor;
            if (columnIndex < 2) {
              backgroundColor = "#F3F3F3"; // First two columns
            } else if (columnIndex < 4) {
              backgroundColor = "#EDEDED"; // Next two columns
            } else {
              backgroundColor = "#E8E8E8"; // Last two columns
            }
            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                key={columnIndex}
                style={{ backgroundColor, border: "1px solid #D9D9D9", padding: "10px" }}
              >
                {cityRows[columnIndex].map((row, rowIndex) => (
                  <div key={rowIndex} style={{ marginBottom: "10px" }}>
                    <TextField
                      className={classes.textField}
                      label="Start Interval"
                      value={row.start_interval}
                      onChange={(e) =>
                        handleCityInputChange(
                          columnIndex,
                          rowIndex,
                          "start_interval",
                          e.target.value
                        )
                      }
                      fullWidth
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      label="End Interval"
                      value={row.end_interval}
                      onChange={(e) =>
                        handleCityInputChange(
                          columnIndex,
                          rowIndex,
                          "end_interval",
                          e.target.value
                        )
                      }
                      fullWidth
                      variant="outlined"
                      style={{ marginTop: "10px" }}
                    />
                    <TextField
                      className={classes.textField}
                      label="A"
                      value={row.a}
                      onChange={(e) =>
                        handleCityInputChange(
                          columnIndex,
                          rowIndex,
                          "a",
                          e.target.value
                        )
                      }
                      fullWidth
                      variant="outlined"
                      style={{ marginTop: "10px" }}
                    />
                    <TextField
                      className={classes.textField}
                      label="B"
                      value={row.b}
                      onChange={(e) =>
                        handleCityInputChange(
                          columnIndex,
                          rowIndex,
                          "b",
                          e.target.value
                        )
                      }
                      fullWidth
                      variant="outlined"
                      style={{ marginTop: "10px" }}
                    />
                    <TextField
                      className={classes.textField}
                      label="C"
                      value={row.c}
                      onChange={(e) =>
                        handleCityInputChange(
                          columnIndex,
                          rowIndex,
                          "c",
                          e.target.value
                        )
                      }
                      fullWidth
                      variant="outlined"
                      style={{ marginTop: "10px" }}
                    />
                  </div>
                ))}
                <Button
                  className={classes.button}
                  variant="outlined"
                  fullWidth
                  onClick={() => addCityRow(columnIndex)}
                >
                  Add Row to{" "}
                  {
                    [
                      "Sedan",
                      "Sedan - Surge",
                      "Hatchback",
                      "Hatchback - Surge",
                      "MUV",
                      "MUV - Surge"
                    ][columnIndex]
                  }
                </Button>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <div className={classes.submitButtonContainer}>
              <Button
                className={classes.submitButton}
                variant="contained"
                onClick={() => handleSubmit("A")}
              >
                Submit Cab A
              </Button>
              <Button
                className={classes.submitButton}
                variant="contained"
                onClick={() => handleSubmit("B")}
              >
                Submit Cab B
              </Button>
              <Button
                className={classes.submitButton}
                variant="contained"
                onClick={() => handleSubmit("C")}
              >
                Submit Cab C
              </Button>
            </div>
          </Grid>
        </Grid>
      )}

      {activeView === "rental" && (
        <>
          <Typography variant="h6" style={{ marginBottom: "20px" }}>
            Admin Rental Constants
          </Typography>
          <TextField
            className={classes.textField}
            label="Base Fare"
            value={rentalConstants.baseFare}
            onChange={(e) => handleRentalChange("baseFare", e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "10px" }}
          />
          <TextField
            className={classes.textField}
            label="Hourly Rate"
            value={rentalConstants.hourlyRate}
            onChange={(e) => handleRentalChange("hourlyRate", e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "10px" }}
          />
          <TextField
            className={classes.textField}
            label="Per Km Rate"
            value={rentalConstants.kmRate}
            onChange={(e) => handleRentalChange("kmRate", e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "10px" }}
          />
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => handleSubmit("rental")}
            style={{ marginTop: "20px" }}
          >
            Submit Rental Constants
          </Button>
        </>
      )}

      {activeView === "outstation" && (
        <>
          <Typography variant="h6" style={{ marginBottom: "20px" }}>
            Admin Outstation Constants
          </Typography>
          <TextField
            className={classes.textField}
            label="Base Fare"
            value={outstationConstants.baseFare}
            onChange={(e) => handleOutstationChange("baseFare", e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: "10px" }}
          />
          <TextField
            className={classes.textField}
            label="Per Km Rate"
            value={outstationConstants.perKmRate}
            onChange={(e) =>
              handleOutstationChange("perKmRate", e.target.value)
            }
            fullWidth
            variant="outlined"
            style={{ marginBottom: "10px" }}
          />
          <TextField
            className={classes.textField}
            label="Driver Allowance"
            value={outstationConstants.driverAllowance}
            onChange={(e) =>
              handleOutstationChange("driverAllowance", e.target.value)
            }
            fullWidth
            variant="outlined"
            style={{ marginBottom: "10px" }}
          />
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => handleSubmit("outstation")}
            style={{ marginTop: "20px" }}
          >
            Submit Outstation Constants
          </Button>
        </>
      )}
    </Card>
  );
}

export const FareCalculatorOld = (distance,time,rateDetails,instructionData, decimal)=>{  

    let baseCalculated =  (parseFloat(rateDetails.rate_per_unit_distance) * parseFloat(distance)) + (parseFloat(rateDetails.rate_per_hour) * (parseFloat(time) / 3600));
    if(rateDetails.base_fare>0){
        baseCalculated = baseCalculated + rateDetails.base_fare;
    }
    if(instructionData && instructionData.parcelTypeSelected){
        baseCalculated = baseCalculated + instructionData.parcelTypeSelected.amount;
    }
    if(instructionData && instructionData.optionSelected){
        baseCalculated = baseCalculated + instructionData.optionSelected.amount;
    }
    let total = baseCalculated > parseFloat(rateDetails.min_fare) ? baseCalculated : parseFloat(rateDetails.min_fare);
    let convenienceFee = 0;
    if(rateDetails.convenience_fee_type && rateDetails.convenience_fee_type == 'flat'){
        convenienceFee = rateDetails.convenience_fees;
    }else{
        convenienceFee = (total*parseFloat(rateDetails.convenience_fees)/100);
    }
    let grand = total + convenienceFee;

    return {
        totalCost:parseFloat(total.toFixed(decimal)),
        grandTotal:parseFloat(grand.toFixed(decimal)),
        convenience_fees:parseFloat(convenienceFee.toFixed(decimal))
    }
     
}

export const FareCalculator = (carType, surgePrice, distance, decimal, rateDetails) => {
  function calculateFare(equation, distance) {
    const a = equation.a;
    const b = equation.b;
    const c = equation.c;
    return a * Math.pow(distance, 2) + b * distance + c;
  }

  // Determine which pricing list to use
  const pricingList = surgePrice
    ? carType.cityRidesPricing.surgePricing
    : carType.cityRidesPricing.normalPricing;

  // Find the correct pricing object based on the distance
  let selectedPricing = null;
  for (let i = 0; i < pricingList.length; i++) {
    const pricing = pricingList[i];
    if (distance >= pricing.startInterval && distance <= pricing.endInterval) {
      selectedPricing = pricing;
      break;
    }
  }

  if (!selectedPricing) {
    throw new Error("Distance does not fall within any pricing interval.");
  }

  // Calculate the fare using the selected quadratic equation
  const fare = calculateFare(selectedPricing, distance);

  // const total =
  //   fare > parseFloat(rateDetails.min_fare)
  //     ? fare
  //     : parseFloat(rateDetails.min_fare);


  return {
    totalCost: parseFloat(fare.toFixed(decimal)),
    grandTotal: parseFloat(fare.toFixed(decimal)),
    convenience_fees: 0
  };
};

